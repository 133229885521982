import React, { useEffect, useRef, useState } from 'react';
import styles from './Order.module.scss';
import { useTranslation } from 'react-i18next';
import { getCartItems, removeCartItem } from '../cart/services/cart.service';
import { CartItem } from '../cart/models';
import { getIfUserExistance } from '../../shared/services';
import { PickupPoint } from '../../shared/map/models';
import { Country, DeliveryMethod, OrderAddress, OrderModel, PaymentMethod } from './models';
import { getCountries } from './services/get-countries.service';
import uuid from 'react-uuid';
import { getDeliveries, getPayments, getPickupPointById, takeOrder } from './services';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import * as yup from "yup";
import { checkLogInStatus } from '../../auth/services/auth.service';
import { UserData } from '../client-panel/components/my-account-info/models';
import { getAvailablePickupPointsForWebshopOrder, getUserDetails } from '../client-panel/components/my-account-info/services';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Address } from '../client-panel/components/my-account-info/models/address.model';
import { PRZELEWY24_PAYMENT_METHOD } from '../payment/online-payments.constants';
import { getPickupPointWithSellingSessions } from '../selling-sessions/service';
import RegulationsModal from '../../shared/ui-elements/regulations-modal/RegulationsModal';
import ClientData from './components/clientData/ClientData';
import PickupPointSelectionPanel from './components/pickupPointSelectionPanel/PickupPointSelectionPanel';
import { PickupPointWithSellingSessionsDto } from './models/PickupPointWithSellingSessionsDto.model';
import { setBasketAsOrdered } from '../../shared/services/basket.service';

export default function Order(props: any) {
    const currency = useSelector((state: RootState) => state.currency);
    const { t, i18n } = useTranslation('translation');
    const navigate = useNavigate();
    const [otherDeliveryAddressChecked, setOtherDeliveryAddressChecked] = useState<boolean>(false);
    const [pickupPointMethodChecked, setPickupPointMethodChecked] = useState<boolean>(false);
    const [isUserAgreed, setIsUserAgreed] = useState<boolean>(false);
    const [cartItems, setCartItems] = useState<CartItem[]>([]);
    const [availablePickupPoints, setAvailablePickupPoints] = useState<PickupPoint[]>([]);
    const [selectedPickupPoint, setSelectedPickupPoint] = useState<PickupPoint | null>();
    const [countries, setCountries] = useState<Country[]>([]);
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
    const [deliveryMethods, setDeliveryMethods] = useState<DeliveryMethod[]>([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>();
    const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState<DeliveryMethod>();
    const [loggedInUserData, setLoggedInUserData] = useState<UserData>();
    const [mainAddress, setMainAddress] = useState<Address>();
    const formRef = useRef<any>();
    const [przelewy24regulationAccept, setPrzelewy24RegulationAccept] = useState(false);
    const [isTakeOrderClicked, setIsTakeOrderClicked] = useState<boolean>(false);

    const [showAllItems, setShowAllItems] = useState(false);
    const displayedItems = showAllItems ? cartItems : cartItems.slice(0, 3);
    const [showRegulationsModal, setShowRegulationsModal] = useState<boolean>(false);
    const [regulationsCode, setRegulationsCode] = useState<string>("");
    //STATES FOR NEXT STEPS OF ORDER PROCCESS
    const [hasBeenClientDataProvided, setHasBeenClientDataProvided] = useState<boolean>(false);
    const [pickupPointsWithSellingSessions, setPickupPointsWithSellingSessions] = useState<PickupPointWithSellingSessionsDto[]>([]);


    //LOGIC FOR NEXT STEPS
    const [showPaymentMethodsForm, setShowPaymentMethodsForm] = useState<boolean>(false);
    const [showTakeOrderButton, setShowTakeOrderButton] = useState<boolean>(false);

    //display error if clicked CONTINUE
    const [displayFormErrors, setDisplayFormErrors] = useState<boolean>(false);

    //display error if user exists
    const [doesUserExists, setDoesUserExists] = useState<boolean>(false);


    const editUserDataButtonAction = () => {
        setShowPaymentMethodsForm(false);
        setShowTakeOrderButton(false);
    }

    const fillDeliveryMethod = () => {
        const deliveryMethodId = localStorage.getItem('orderDeliveryMethodId')?.trim();
        if (deliveryMethodId) {
            const selectedDeliveryMethodLocalStorage = deliveryMethods.find(method => method.id === deliveryMethodId);
            if (selectedDeliveryMethodLocalStorage) {
                setSelectedDeliveryMethod(selectedDeliveryMethodLocalStorage);
            }
        }
    };

    const fillPickupPoint = () => {
        const pickupPointId = localStorage.getItem('pickupPointsFilter')?.trim();
        if (pickupPointId) {
            const selectedPickupPointLocalStorage = pickupPointsWithSellingSessions.find(point => point.pickupPointToReturn.id === pickupPointId)?.pickupPointToReturn;
            if (selectedPickupPointLocalStorage) {
                setSelectedPickupPoint(selectedPickupPointLocalStorage);
                setPickupPointMethodChecked(true);
            }
        }
    }


    useEffect(() => {
        const shouldShowTakeOrderButton = () => {
            if (selectedDeliveryMethod && selectedPaymentMethod && validatePrzelewy24Method() && showPaymentMethodsForm) {
                if (!pickupPointMethodChecked || (pickupPointMethodChecked && selectedPickupPoint)) {
                    return true;
                }
            }
            return false;
        };

        setShowTakeOrderButton(shouldShowTakeOrderButton());
    }, [
        isUserAgreed,
        przelewy24regulationAccept,
        selectedPaymentMethod,
        selectedPickupPoint,
        selectedDeliveryMethod,
        showPaymentMethodsForm
    ]);

    const handleShowRegulationsModal = (code: string): void => {
        setShowRegulationsModal(true);
        setRegulationsCode(code);
    }

    const handleCloseRegulationsModal = (): void => {
        setShowRegulationsModal(false);
        setRegulationsCode("");
    }

    useEffect(() => {
        if (cartItems.length > 0) {
            getPickupPointWithSellingSessions(cartItems).then((response: any) => {
                if (response) {
                    const data = response.data as PickupPointWithSellingSessionsDto[];
                    setPickupPointsWithSellingSessions(data);
                    const pickupPoints: PickupPoint[] = data.map(item => item.pickupPointToReturn);
                    setAvailablePickupPoints(pickupPoints);
                }
            }).catch(error => {
                console.error('Error fetching pickup points:', error);
            });
        }
    }, [cartItems]);

    useEffect(() => {
        formRef.current.validateForm();
    }, [otherDeliveryAddressChecked])

    useEffect(() => {
        fillDeliveryMethod();
    }, [deliveryMethods])

    useEffect(() => {
        fillPickupPoint();
    }, [pickupPointsWithSellingSessions])

    useEffect(() => {
        const mainAddress = loggedInUserData?.address?.find(address => address.addressType === 'MAIN');
        if (mainAddress) {
            setMainAddress(mainAddress as unknown as Address);
        }
    }, [loggedInUserData]);



    useEffect(() => {
        getCartItemsOnInit();
        getCountriesOnOnit();
        getPaymentMethodsOnOnit();
        getDeliveryMethodsOnOnit();

        if (checkLogInStatus()) {
            getUserData();
        }
        formRef.current.validateForm();
    }, []);

    const handleOtherDeliveryAddreessChecked = (order: OrderModel, setValues: any): void => {
        setOtherDeliveryAddressChecked(!otherDeliveryAddressChecked);
        fillOrderAddressOnInit(order, setValues)
    };

    const handleOtherDeliveryAddreessChange = (order: OrderModel, address: Address, setValues: any): void => {
        const updatedOrder = {
            ...order,
            orderAddress: address
        };
        setValues(updatedOrder);
    }

    const fillOrderAddressOnInit = (order: OrderModel, setValues: any) => {
        if (loggedInUserData !== null && loggedInUserData?.address && loggedInUserData?.address.length > 0) {
            const updatedOrder = {
                ...order,
                orderAddress: loggedInUserData?.address[0]
            };
            setValues(updatedOrder)
        }
    }


    const handleRegulationAcceptChange = () => {
        setPrzelewy24RegulationAccept(!przelewy24regulationAccept);
    };

    const findCountryByCode = (code: string): string => {
        const result = countries.find(country => country.code === code)?.id;
        if (!result) return "";
        return result;
    };


    const getCartItemsOnInit = (): void => {
        getCartItems().then((result) => {
            if (result) {
                setCartItems(result as CartItem[]);
                getAvailablePickupPointsForWebshopOrderOnInit(result as CartItem[]);
            }
        });
    };

    const getAvailablePickupPointsForWebshopOrderOnInit = (items: CartItem[]): void => {
        getAvailablePickupPointsForWebshopOrder(items).then((result) => {
            if (result) {
                setAvailablePickupPoints(result.data as PickupPoint[]);
            }
        });
    };

    const getCountriesOnOnit = (): void => {
        getCountries().then((response) => {
            if (response) {
                setCountries(response.data as Country[]);
            }
        })
    };

    const getPaymentMethodsOnOnit = (): void => {
        getPayments().then((response) => {
            if (response) {
                setPaymentMethods(response.data as PaymentMethod[])
            }
        })
    };

    const getDeliveryMethodsOnOnit = (): void => {
        getDeliveries().then((response) => {
            if (response) {
                setDeliveryMethods(response.data as DeliveryMethod[])
            }
        })
    };

    const handlePaymentMethodChange = (payment: PaymentMethod): void => {
        setSelectedPaymentMethod(payment);
    };

    const handleUserAgreeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsUserAgreed(event.target.checked);
    };

    const getUserData = (): void => {
        getUserDetails().then((response) => {
            if (response) {
                setLoggedInUserData(response.data as UserData);
            }
        })
    };


    const validatePrzelewy24Method = (): boolean => {
        if (selectedPaymentMethod?.code === PRZELEWY24_PAYMENT_METHOD) {
            return true;
        } else if (selectedPaymentMethod?.code !== PRZELEWY24_PAYMENT_METHOD) {
            return true;
        }
        return false;
    }

    const checkUserExistance = (email: string): Promise<boolean> => {
        return getIfUserExistance(email).then(response => {
            return response.data;
        });
    };

    let validationSchemaWithoutDeliveryAddress = yup.object().shape({
        userFirstName: yup
            .string()
            .matches(/^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\s]+$/, 'Must be only letters')
            .required(),
        userLastName: yup
            .string()
            .matches(/^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\s]+$/, 'Must be only letters')
            .required(),
        userEmail: yup
            .string()
            .email('Invalid email format')
            .required('Email is required'),
        userPhoneNumber: yup
            .string()
            .matches(/^\+?[0-9]+$/)
            .required(),
        userAddress: yup.object({
            country: yup.string().required(),
            buildingNumber: yup.string().trim().required().matches(/^[a-zA-Z0-9\s]*$/, 'Building number must not contain special characters'),
            flatNumber: yup.string().matches(/^[a-zA-Z0-9\s]*$/, 'Flat number must not contain special characters'),
            streetName: yup.string().trim().required().matches(/^[a-zA-ZąĄćĆęĘłŁńŃóÓśŚżŻźŹ0-9\s]*$/, 'Street name must not contain special characters'),
            city: yup.string().trim().required().matches(/^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\s]+$/, 'Must be only letters'),
            postalCode: yup.string().required(),
        }),
    });

    let validationSchemaWithDeliveryAddress = yup.object().shape({
        userFirstName: yup
            .string()
            .matches(/^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\s]+$/, 'Must be only letters')
            .required(),
        userLastName: yup
            .string()
            .matches(/^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\s]+$/, 'Must be only letters')
            .required(),
        userEmail: yup
            .string()
            .email('Invalid email format')
            .required('Email is required'),
        userPhoneNumber: yup
            .string()
            .matches(/^\+?[0-9]+$/)
            .required(),
        userAddress: yup.object({
            country: yup.string().required(),
            buildingNumber: yup.string().trim().required().matches(/^[a-zA-Z0-9\s]*$/, 'Building number must not contain special characters'),
            flatNumber: yup.string().matches(/^[a-zA-Z0-9\s]*$/, 'Flat number must not contain special characters'),
            streetName: yup.string().trim().required().matches(/^[a-zA-ZąĄćĆęĘłŁńŃóÓśŚżŻźŹ0-9\s]*$/, 'Street name must not contain special characters'),
            city: yup.string().trim().required().matches(/^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\s]+$/, 'Must be only letters'),
            postalCode: yup.string().required(),
        }),
        orderAddress: yup.object({
            country: yup.string().required(),
            buildingNumber: yup.string().trim().required().matches(/^[a-zA-Z0-9\s]*$/, 'Building number must not contain special characters'),
            flatNumber: yup.string().matches(/^[a-zA-Z0-9\s]*$/, 'Flat number must not contain special characters'),
            streetName: yup.string().trim().required().matches(/^[a-zA-ZąĄćĆęĘłŁńŃóÓśŚżŻźŹ0-9\s]*$/, 'Street name must not contain special characters'),
            city: yup.string().trim().required().matches(/^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\s]+$/, 'Must be only letters'),
            postalCode: yup.string().required(),
        })
    });


    const hasUserDataWithoutDeliveryAddressErrors = async (values: any): Promise<boolean> => {
        setDisplayFormErrors(true);
        const userExist = await checkUserExistance(values.userEmail);
        try {
            const validatedData = await validationSchemaWithoutDeliveryAddress.validate(values);
            if (userExist && !localStorage.getItem('token') && values.shouldCreateAccount) {
                setDoesUserExists(true);
                return true;
            }
            else {
                setDoesUserExists(false);
                setShowPaymentMethodsForm(true);
                return false;
            }
        } catch (err) {
            return true;
        }
    };


    const hasUserDataWithDeliveryAddressErrors = async (values: any): Promise<boolean> => {
        setDisplayFormErrors(true);
        const userExist = await checkUserExistance(values.userEmail);
        try {
            await validationSchemaWithDeliveryAddress.validate(values);
            setShowPaymentMethodsForm(true);
            if (userExist && !localStorage.getItem('token') && values.shouldCreateAccount) {
                setDoesUserExists(true);
                return true;
            }
            else {
                setDoesUserExists(false);
                setShowPaymentMethodsForm(true);
                return false;
            }
        } catch (error) {
            return true;
        }
    };


    const calculateTotalPriceWithVAT = (items: CartItem[]): string => {
        let sum = 0;
        items.forEach(it => {
            sum += it.totalPrice;
        })

        return sum.toFixed(2);
    };

    const calculateTotalPriceWithDelivery = (items: CartItem[]): string => {
        let sum = 0;
        items.forEach(it => {
            sum += it.totalPrice;
        })
        if (selectedDeliveryMethod) {
            let price = deliveryMethods.find((x) => x.id === selectedDeliveryMethod.id)?.price;
            if (price !== null && price !== undefined) {
                sum += price;
            }
        }
        return sum.toFixed(2);
    };

    const shouldDisableTakeOrderButton = (): boolean => {
        if (!isUserAgreed || (selectedPaymentMethod?.code === PRZELEWY24_PAYMENT_METHOD && (!isUserAgreed || !przelewy24regulationAccept))) {
            return true;
        } else {
            return false;
        }
    }

    const returnToShopping = (): void => {
        navigate('/');
    }

    const handleSubmit = async (values: any, isValid: boolean): Promise<void> => {
        if (isTakeOrderClicked) return;
        setIsTakeOrderClicked(true);
        const languageCode = localStorage.getItem('lang') || "pl";
        const order: OrderModel = {
            paymentMethod: selectedPaymentMethod?.id as string,
            deliveryMethod: selectedDeliveryMethod?.id as string,
            pickupPointId: selectedPickupPoint ? selectedPickupPoint?.id as string : undefined,
            userFirstName: values.userFirstName,
            userLastName: values.userLastName,
            userEmail: values.userEmail,
            userPhoneNumber: values.userPhoneNumber,
            userAddress: {
                city: values.userAddress.city,
                buildingNumber: values.userAddress.buildingNumber,
                flatNumber: values.userAddress.flatNumber,
                region: "",
                country: values.userAddress.country,
                postalCode: values.userAddress.postalCode,
                streetName: values.userAddress.streetName
            },
            orderAddress: otherDeliveryAddressChecked && !pickupPointMethodChecked ? {
                city: values.orderAddress.city,
                buildingNumber: values.orderAddress.buildingNumber,
                flatNumber: values.orderAddress.flatNumber,
                region: "",
                country: values.orderAddress.country,
                postalCode: values.orderAddress.postalCode,
                streetName: values.orderAddress.streetName
            } : undefined,
            orderNotes: values.orderNotes,
            shouldCreateAccount: values.shouldCreateAccount,
            orderEmailConfirmationLanguageCode: languageCode,
            redirectUrlWhenCreateUser: values.shouldCreateAccount ? process.env.REACT_APP_WEB_SHOP_LOGIN_PAGE as string : "",
            orderItems: cartItems?.map(item => {
                return {
                    productId: item.productId,
                    quantity: item.quantity,
                    netPrice: item.pricePerUnit,
                    productName: item.name,
                    bruttoWorth: item.totalPrice,
                    vatRate: item.vat
                }
            }),
            totalPrice: Number(calculateTotalPriceWithVAT(cartItems)),
        };
        const userExist = await checkUserExistance(order.userEmail);
        if (isValid && selectedPaymentMethod && validatePrzelewy24Method()) {
            if (userExist && !localStorage.getItem('token') && values.shouldCreateAccount) {
                setIsTakeOrderClicked(false);
            }
            else {
                takeOrder(order).then((response) => {
                    if (response?.status === 200) {
                        const token = localStorage.getItem('token');
                        if (!token) {
                            setBasketAsOrdered(values.userFirstName, values.userLastName);
                        }
                        else {
                            setBasketAsOrdered();
                        }
                        cartItems.forEach(x => { removeCartItem(x.id, true) });
                        props.onCartItemRemoved();
                        navigate(`/order-confirmation/${response.data}`, {
                            state: {
                                order: { ...order },
                            }
                        });
                        if (values.shouldCreateAccount) {
                            toast.success(t("order.accountCreated"));
                        }
                    }
                    else {
                        setIsTakeOrderClicked(false);
                    }
                }).catch(() => {
                    setIsTakeOrderClicked(false);
                });
            }
        }
        else {
            setIsTakeOrderClicked(false);
            const hasDeliveryAddressErrors = await hasUserDataWithDeliveryAddressErrors(values);
            const hasNonDeliveryAddressErrors = await hasUserDataWithoutDeliveryAddressErrors(values);
            if (!hasDeliveryAddressErrors && !hasNonDeliveryAddressErrors) {
                setHasBeenClientDataProvided(true);
            }
        }
    }

    return (
        <Formik enableReinitialize={true} initialValues={{
            userFirstName: loggedInUserData ? loggedInUserData?.user?.firstName : "",
            userLastName: loggedInUserData ? loggedInUserData?.user?.lastName : "",
            userEmail: loggedInUserData ? loggedInUserData?.user?.email : "",
            userPhoneNumber: loggedInUserData ?
                (loggedInUserData?.contact?.phone ? loggedInUserData?.contact?.phone :
                    (loggedInUserData?.contact?.mobile ? loggedInUserData?.contact?.mobile : "")
                ) : "",
            userAddress: {
                country: mainAddress ? mainAddress?.country : findCountryByCode("PL"),
                region: "",
                buildingNumber: mainAddress ? mainAddress?.buildingNumber : "",
                streetName: mainAddress ? mainAddress?.streetName : "",
                flatNumber: mainAddress ? mainAddress?.flatNumber : "",
                city: mainAddress ? mainAddress?.city : "",
                postalCode: mainAddress ? mainAddress?.postalCode : ""
            },
            orderAddress: {
                country: findCountryByCode("PL"),
                region: "",
                buildingNumber: "",
                streetName: "",
                flatNumber: "",
                city: "",
                postalCode: ""
            },
            orderNotes: "",
            shouldCreateAccount: false
        }} onSubmit={() => { }}
            validationSchema={(otherDeliveryAddressChecked && !pickupPointMethodChecked) ? validationSchemaWithDeliveryAddress : validationSchemaWithoutDeliveryAddress}
            innerRef={formRef} validateOnMount>
            {({ values, setValues, errors, handleChange, isValid }) =>
                <Form onMouseEnter={() => { }}>
                    <div className={styles.decor}></div>
                    <div className={styles.main_container}>
                        <div className={styles.first_column}>
                            {!showPaymentMethodsForm ?
                                <div className={styles.billing_details}>
                                    <div className={styles.header_container}>
                                        <div className={styles.title}>
                                            {t("order.billingDetails.title")}
                                        </div>
                                    </div>
                                    <div className={styles.double_fields_wrapper}>
                                        <div className={errors.userFirstName && displayFormErrors ? `${styles.field} ${styles.error}` : styles.field}>
                                            <label htmlFor="userFirstName">{t("order.billingDetails.firstName")}</label>
                                            <input
                                                id="first_name"
                                                type="text"
                                                name="userFirstName"
                                                placeholder={errors.userFirstName && displayFormErrors ? 'To pole jest wymagane' : ''}
                                                value={values.userFirstName}
                                                onChange={handleChange}
                                                className={errors.userFirstName && displayFormErrors ? styles.errorText : ''}
                                            />
                                        </div>
                                        <div className={errors.userLastName && displayFormErrors ? `${styles.field} ${styles.error}` : styles.field}>
                                            <label htmlFor="userLastName">{t("order.billingDetails.lastName")}</label>
                                            <input
                                                id="last_name"
                                                type="text"
                                                placeholder={errors.userLastName && displayFormErrors ? 'To pole jest wymagane' : ''}
                                                className={errors.userLastName && displayFormErrors ? styles.errorText : ''}
                                                value={values.userLastName}
                                                name="userLastName"
                                                onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className={styles.double_fields_wrapper}>
                                        <div className={(errors.userEmail && displayFormErrors) || doesUserExists ? `${styles.field} ${styles.error}` : styles.field}>
                                            <label htmlFor="userEmail">{t("order.billingDetails.email")}</label>
                                            <input
                                                id="email"
                                                type="text"
                                                name="userEmail"
                                                placeholder={errors.userEmail && displayFormErrors ? 'To pole jest wymagane' : ''}
                                                className={(errors.userEmail && displayFormErrors) || doesUserExists ? styles.errorText : ''}
                                                value={values.userEmail}
                                                onChange={handleChange} />

                                        </div>

                                        <div className={errors.userPhoneNumber && displayFormErrors ? `${styles.field} ${styles.error}` : styles.field}>
                                            <label htmlFor="userPhoneNumber">{t("order.billingDetails.phone")}</label>
                                            <input
                                                id="phone"
                                                type="text"
                                                name="userPhoneNumber"
                                                value={values.userPhoneNumber}
                                                placeholder={errors.userPhoneNumber && displayFormErrors ? 'To pole jest wymagane' : ''}
                                                className={errors.userPhoneNumber && displayFormErrors ? styles.errorText : ''}
                                                onChange={handleChange} />
                                        </div>

                                    </div>
                                    {doesUserExists &&
                                        <div className={`${styles.error_color} ${styles.email_error}`}>
                                            Konto z tym adresem e-mail już istnieje. Proszę podać inny.
                                        </div>
                                    }
                                    <div className={errors.userAddress?.streetName && displayFormErrors ? `${styles.single_field_with_label_wrapper} ${styles.error}` : `${styles.single_field_with_label_wrapper} `}>
                                        <label htmlFor="userAddress.streetName">{t("order.billingDetails.streetName")}</label>
                                        <input id="street_name"
                                            type="text" name=
                                            "userAddress.streetName"
                                            placeholder={errors.userAddress?.streetName && displayFormErrors ? 'To pole jest wymagane' : ''}
                                            className={errors.userAddress?.streetName && displayFormErrors ? styles.errorText : ''}
                                            value={values.userAddress.streetName}
                                            onChange={handleChange} />
                                    </div>
                                    <div className={styles.double_fields_wrapper}>
                                        <div className={errors.userAddress?.buildingNumber && displayFormErrors ? `${styles.field} ${styles.error}` : styles.field}>
                                            <label htmlFor="userAddress.buildingNumber">{t("order.billingDetails.buildingNumber")}</label>
                                            <input
                                                id="building_number"
                                                type="text"
                                                name="userAddress.buildingNumber"
                                                placeholder={errors.userAddress?.buildingNumber && displayFormErrors ? 'To pole jest wymagane' : ''}
                                                className={errors.userAddress?.buildingNumber && displayFormErrors ? styles.errorText : ''}
                                                value={values.userAddress.buildingNumber}
                                                onChange={handleChange} />
                                        </div>
                                        <div className={errors.userAddress?.flatNumber ? `${styles.field} ${styles.error}` : styles.field}>
                                            <label htmlFor="userAddress.flatNumber">{t("order.billingDetails.flatNumber")}</label>
                                            <input
                                                id="flat_number"
                                                type="text"
                                                name="userAddress.flatNumber"
                                                placeholder={errors.userAddress?.flatNumber && displayFormErrors ? 'To pole jest wymagane' : ''}
                                                className={errors.userAddress?.flatNumber && displayFormErrors ? styles.errorText : ''}
                                                value={values.userAddress.flatNumber}
                                                onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className={styles.double_fields_wrapper}>
                                        <div className={errors.userAddress?.postalCode && displayFormErrors ? `${styles.field} ${styles.error}` : styles.field}>
                                            <label htmlFor="userAddress.postalCode">{t("order.billingDetails.postalCode")}</label>
                                            <input
                                                id="postal_code"
                                                type="text"
                                                name="userAddress.postalCode"
                                                placeholder={errors.userAddress?.postalCode && displayFormErrors ? 'To pole jest wymagane' : ''}
                                                className={errors.userAddress?.postalCode && displayFormErrors ? styles.errorText : ''}
                                                value={values.userAddress.postalCode}
                                                onChange={handleChange} />
                                        </div>
                                        <div className={errors.userAddress?.city && displayFormErrors ? `${styles.field} ${styles.error}` : styles.field}>
                                            <label htmlFor="userAddress.city">{t("order.billingDetails.cityName")}</label>
                                            <input
                                                id="city_name"
                                                type="text"
                                                name="userAddress.city"
                                                placeholder={errors.userAddress?.city && displayFormErrors ? 'To pole jest wymagane' : ''}
                                                className={errors.userAddress?.city && displayFormErrors ? styles.errorText : ''}
                                                value={values.userAddress.city}
                                                onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className={!errors.userAddress?.country ? styles.single_field_with_label_wrapper : `${styles.single_field_with_label_wrapper} ${styles.error}`}>
                                        <label htmlFor="userAddress.country">{t("order.billingDetails.country")}</label>
                                        <div className={styles.separator}></div>
                                        <select name="userAddress.country" value={values.userAddress.country} onChange={handleChange}>
                                            {
                                                <option></option>
                                            }
                                            {
                                                countries && countries?.map((country: Country) => (
                                                    <option key={uuid()} value={country?.id}>{t(`countriesNames.${country?.name}`)}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className={styles.checkbox_with_label}>
                                        {
                                            !checkLogInStatus() &&
                                            <>
                                                <input checked={values.shouldCreateAccount} type="checkbox" id="create_account" name="shouldCreateAccount" onChange={handleChange} />
                                                <label className={styles.checkbox_label}>{t("order.billingDetails.createAccount")}</label>
                                            </>
                                        }
                                    </div>
                                    <div className={`${styles.header_container} ${styles.checkbox_container}`}>
                                        {!pickupPointMethodChecked &&
                                            <>
                                                <input checked={otherDeliveryAddressChecked} type="checkbox" id="other_address_shipping" onChange={() => { handleOtherDeliveryAddreessChecked(values as OrderModel, setValues) }} />
                                                <label className={styles.checkbox_label}>
                                                    {t("order.billingDetails.shipToAnotherAddress")}
                                                </label>
                                            </>
                                        }
                                        {otherDeliveryAddressChecked && checkLogInStatus() && (
                                            <select name="orderAddress" value={JSON.stringify(values.orderAddress)} onChange={(event) => handleOtherDeliveryAddreessChange(values as OrderModel, JSON.parse(event.target.value) as Address, setValues)}>
                                                {loggedInUserData?.address && loggedInUserData?.address?.map((address: Address) => (
                                                    <option key={address.id} value={JSON.stringify(address)}>
                                                        {`${address.city}, ${address.streetName} ${address.buildingNumber}`}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    </div>
                                    {
                                        otherDeliveryAddressChecked &&
                                        <>
                                            <div className={!errors.orderAddress?.streetName ? styles.single_field_with_label_wrapper : `${styles.single_field_with_label_wrapper} ${styles.error}`}>
                                                <label htmlFor='orderAddress.streetName'>{t("register.streetName")}</label>
                                                <input
                                                    id="street_name"
                                                    type="text"
                                                    name="orderAddress.streetName"
                                                    placeholder={errors.orderAddress?.streetName ? 'To pole jest wymagane' : ''}
                                                    className={errors.orderAddress?.streetName ? styles.errorText : ''}
                                                    value={values.orderAddress.streetName}
                                                    onChange={handleChange} />
                                            </div>

                                            <div className={styles.double_fields_wrapper}>
                                                <div className={!errors.orderAddress?.buildingNumber ? styles.field : `${styles.field} ${styles.error}`}>
                                                    <label htmlFor="orderAddress.buildingNumber">{t("order.billingDetails.buildingNumber")}</label>
                                                    <input
                                                        id="building_number"
                                                        type="text"
                                                        name="orderAddress.buildingNumber"
                                                        value={values.orderAddress.buildingNumber}
                                                        onChange={handleChange}
                                                        placeholder={errors.orderAddress?.buildingNumber ? 'To pole jest wymagane' : ''}
                                                        className={errors.orderAddress?.buildingNumber ? styles.errorText : ''}
                                                    />
                                                </div>
                                                <div className={!errors.orderAddress?.flatNumber ? styles.field : `${styles.field} ${styles.error}`}>
                                                    <label htmlFor="orderAddress.flatNumber">{t("order.billingDetails.flatNumber")}</label>
                                                    <input
                                                        id="flat_number"
                                                        type="text"
                                                        name="orderAddress.flatNumber"
                                                        value={values.orderAddress.flatNumber}
                                                        placeholder={errors.orderAddress?.flatNumber ? 'To pole jest wymagane' : ''}
                                                        className={errors.orderAddress?.flatNumber ? styles.errorText : ''}
                                                        onChange={handleChange} />
                                                </div>

                                            </div>
                                            <div className={styles.double_fields_wrapper}>
                                                <div className={!errors.orderAddress?.city ? styles.field : `${styles.field} ${styles.error}`}>
                                                    <label htmlFor="orderAddress.city">{t("order.billingDetails.cityName")}</label>
                                                    <input
                                                        id="city_name"
                                                        type="text"
                                                        name="orderAddress.city"
                                                        value={values.orderAddress.city}
                                                        placeholder={errors.orderAddress?.city ? 'To pole jest wymagane' : ''}
                                                        className={errors.orderAddress?.city ? styles.errorText : ''}
                                                        onChange={handleChange} />
                                                </div>
                                                <div className={!errors.orderAddress?.postalCode ? styles.field : `${styles.field} ${styles.error}`}>
                                                    <label htmlFor="orderAddress.postalCode">{t("order.billingDetails.postalCode")}</label>
                                                    <input
                                                        id="postal_code"
                                                        type="text"
                                                        name="orderAddress.postalCode"
                                                        placeholder={errors.orderAddress?.postalCode ? 'To pole jest wymagane' : ''}
                                                        className={errors.orderAddress?.postalCode ? styles.errorText : ''}
                                                        value={values.orderAddress.postalCode}
                                                        onChange={handleChange} />
                                                </div>
                                            </div>
                                            <div className={!errors.orderAddress?.country ? styles.single_field_with_label_wrapper : `${styles.single_field_with_label_wrapper} ${styles.error}`}>
                                                <label htmlFor='orderAddress.country'>{t("order.billingDetails.country")}</label>
                                                <div className={styles.separator}></div>
                                                <select name="orderAddress.country" value={values.orderAddress.country} onChange={handleChange}>

                                                    {
                                                        countries && countries?.map((country: Country) => (
                                                            <option key={uuid()} value={country?.id}>{t(`countriesNames.${country?.name}`)}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </>
                                    }
                                    <div className={styles.single_field_with_label_wrapper}>
                                        <label htmlFor='orderNotes'>{t("order.billingDetails.orderNotes")}</label>
                                        <textarea placeholder={`${t("order.billingDetails.orderNotesPlaceholder")}`} name="orderNotes" value={values.orderNotes} onChange={handleChange}></textarea>
                                    </div>
                                    <div className={styles.continue_button_div}>
                                        <button
                                            onClick={() => {
                                                !otherDeliveryAddressChecked
                                                    ? hasUserDataWithoutDeliveryAddressErrors(values)
                                                    : hasUserDataWithDeliveryAddressErrors(values)
                                            }}>
                                            KONTYNUUJ
                                        </button>
                                    </div>
                                </div>
                                :
                                <div className={styles.billing_details_preview}>
                                    <div className={styles.header_container}>
                                        <div className={styles.title}>
                                            {t("order.billingDetails.title")}
                                        </div>
                                    </div>
                                    <ClientData orderModel={values as OrderModel}
                                        otherDeliveryAddressChecked={otherDeliveryAddressChecked}
                                        pickupPointMethodChecked={pickupPointMethodChecked}></ClientData>
                                    <div className={styles.continue_button_div}>
                                        <button onClick={() => { editUserDataButtonAction() }}>EDYTUJ</button>
                                    </div>
                                </div>
                            }

                            {showPaymentMethodsForm &&
                                <div className={styles.payment_method_wrapper}>
                                    <div className={styles.header_container}>
                                        <div className={styles.title}>
                                            {t("order.paymentMethod.title")}
                                        </div>
                                    </div>
                                    <>
                                        < div className={selectedPaymentMethod ? '' : `${styles.radio_wrapper} ${styles.error}`}>
                                            {!selectedPaymentMethod && <div className={styles.errorText}>Wybierz metodę płatności!</div>}
                                            {
                                                paymentMethods && paymentMethods?.map((item: PaymentMethod) => (
                                                    <div key={uuid()} className={styles.radio_with_label + ' ' + styles.payment_item}>
                                                        <div>
                                                            <input
                                                                type="radio"
                                                                checked={selectedPaymentMethod?.id === item?.id}
                                                                value={item?.id}
                                                                onChange={() => handlePaymentMethodChange(item)}
                                                            />
                                                            <span>{item?.name}</span>
                                                        </div>


                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className={styles.privacy_and_terms_container}>
                                            <div className={styles.info_for_user}>
                                                <span>{t("order.orderSummary.infoForUser")} <a className={styles.green_color} onClick={() => handleShowRegulationsModal("PRIVACY_POLICY")}>{t("order.orderSummary.privacyPolicy")}</a></span>
                                            </div>
                                            <div className={styles.user_agree}>
                                                <input type="checkbox" className={styles.pointer} checked={isUserAgreed} onChange={handleUserAgreeChange} />
                                                <span className={!isUserAgreed ? styles.error_color : ''}>{t("order.orderSummary.userAgreeText")} <a className={!isUserAgreed ? styles.error_color : styles.green_color} onClick={() => handleShowRegulationsModal("REGULATIONS")}  > {t("order.orderSummary.termsAndConditions")}</a></span>
                                            </div>
                                            {selectedPaymentMethod?.code === PRZELEWY24_PAYMENT_METHOD && (
                                                <div className={styles.user_agree}>
                                                    <input type="checkbox" onChange={handleRegulationAcceptChange} checked={przelewy24regulationAccept} className={styles.pointer} />
                                                    <span className={!przelewy24regulationAccept ? styles.error_color : ''}>{t("order.orderSummary.przelewy24RequirementsAcceptance.declaration")}<a className={!przelewy24regulationAccept ? styles.error_color : styles.green_color} onClick={() => handleShowRegulationsModal("PRZELEWY24_REGULATIONS")}>
                                                        {" "}{t("order.orderSummary.przelewy24RequirementsAcceptance.regulations_link.text")}</a>
                                                        {" "}{t("order.orderSummary.przelewy24RequirementsAcceptance.and")}
                                                        {" "}<a className={!przelewy24regulationAccept ? styles.error_color : styles.green_color} onClick={() => handleShowRegulationsModal("PRZELEWY24_OBLIGATIONS")}>{" "}{t("order.orderSummary.przelewy24RequirementsAcceptance.information_obligation_link.text")}
                                                        </a> Przelewy24</span>
                                                </div>
                                            )}
                                            <div>
                                                {showRegulationsModal && <RegulationsModal code={regulationsCode} close={() => handleCloseRegulationsModal()}></RegulationsModal>}
                                            </div>
                                        </div>
                                    </>
                                </div>
                            }
                            {showTakeOrderButton &&
                                <div className={styles.edit_or_place_order_buttons_container}>
                                    <div className={`${styles.edit_or_place_order_button} ${styles.flex_start}`}>
                                        <button onClick={returnToShopping}>{t("order.orderSummary.returnToShopping")}</button>
                                    </div>
                                    <div className={styles.edit_or_place_order_button}>
                                        <button disabled={shouldDisableTakeOrderButton()} onClick={() => handleSubmit(values, isValid)} type="submit" >{!isTakeOrderClicked ? t("order.orderSummary.placeOrder") : t("order.orderSummary.orderProceeding")}</button>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={styles.second_column}>
                            <div className={styles.order_summary}>
                                <div className={styles.header_container}>
                                    <div className={styles.title}>
                                        {t("myAccountInfo.orders.order")}
                                    </div>
                                </div>

                                <div className={styles.order_details}>
                                    <div className={styles.sub_header_container + ' ' + styles.header_whit_button}>
                                        <div className={styles.title}>
                                            {t("order.orderSummary.products")}
                                        </div>
                                        <div className={styles.edit_or_place_order_button}>
                                            <button onClick={() => navigate("/cart")}>{t("order.orderSummary.editOrder")}</button>
                                        </div>
                                    </div>

                                    {displayedItems.map((item: CartItem) => (
                                        <div className={styles.order_item} key={uuid()}>
                                            <div className={styles.left_container}>
                                                <span>{item.name} x{item.quantity}</span>
                                            </div>
                                            <div className={styles.total_container}>
                                                <span><strong>{item.totalPrice.toFixed(2) + " " + currency}</strong></span>
                                            </div>
                                        </div>
                                    ))}
                                    {cartItems.length > 3 && (
                                        <div className={styles.show_more} onClick={() => setShowAllItems(!showAllItems)}>
                                            {showAllItems ? 'Zwiń ▲' : 'Lista pozostałych produktów ▼'}
                                        </div>
                                    )}

                                </div>
                            </div>
                            <div className={styles.summary_data_container}>
                                <div className={styles.data_container}>
                                    <span>{t("order.orderSummary.subtotal")}</span>
                                    <span className={styles.data_value}>{calculateTotalPriceWithVAT(cartItems) + " " + currency}</span>
                                </div>
                                <div className={styles.data_container}>
                                    <span>{t("order.orderSummary.shipping")}</span>
                                    <span className={styles.data_value}>
                                        {selectedDeliveryMethod?.price ?
                                            (selectedDeliveryMethod.price.toFixed(2) + " " + currency) :
                                            "0.00 " + currency}
                                    </span>
                                </div>
                                <div className={styles.data_container}>
                                    <span>{t("order.orderSummary.total")}</span>
                                    <span className={styles.data_value}>{(Number(calculateTotalPriceWithDelivery(cartItems))).toFixed(2) + " " + currency}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </Form>
            }
        </Formik >
    )
}
