import React, { useEffect, useState } from 'react';
import styles from './OrderConfirmation.module.scss';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Country, DeliveryMethod, OrderItem, OrderModel, PaymentMethod } from '../order/models';
import { useTranslation } from 'react-i18next';
import { getCountries, getDeliveries, getPayments } from '../order/services';
import { PickupPoint } from '../../shared/map/models';
import { getPickupPointById } from '../order/services';
import uuid from 'react-uuid';
import { getProductImagesService, decodeImageService, calculateBrutto, formatDateTimeService } from '../../shared/services';
import { ProductImage } from '../../shared/product-card/models/product-image.model';
import { OrderItemImage } from './models';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { getOrderDetails } from '../client-panel/components/my-account-info/services';
import { OrderDetailsModel } from '../order/models/order-details.model';
import { PRZELEWY24_PAYMENT_METHOD } from '../payment/online-payments.constants';
import { getSellingSessionsPaginatedService } from '../selling-sessions/service';
import { SellingSession } from '../selling-sessions/models';
import OrderConfirmationSummary from './components/order-confirmation-summary/OrderConfirmationSummary';
import OrderConfirmationProducts from './components/order-confirmation-products/OrderConfirmationProducts';


export default function OrderConfirmation() {
    const currency = useSelector((state: RootState) => state.currency);
    const location = useLocation();
    const navigate = useNavigate();
    const [orderDetails, setOrderDetails] = useState<OrderDetailsModel>();
    const [countries, setCountries] = useState<Country[]>();
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
    const [deliveryMethods, setDeliveryMethods] = useState<DeliveryMethod[]>([]);
    const [pickupPoint, setPickupPoint] = useState<PickupPoint>();
    const [productImages, setProductImages] = useState<OrderItemImage[]>([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>();
    const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState<DeliveryMethod>();
    const { t, i18n } = useTranslation('translation');
    const { orderId } = useParams();
    const emptyGuid = "00000000-0000-0000-0000-000000000000";
    const [sellingSessions, setSellingSessions] = useState<SellingSession[]>();
    const [isPayClicked, setIsPayClicked] = useState<boolean>(false);

    useEffect(() => {
        window.scrollTo({ top: 0 });
        getPaymentMethodsOnOnit();
        getDeliveryMethodsOnOnit();
    }, []);

    useEffect(() => {
        if (orderId) {
            getOrderData(orderId);
        }
    }, [orderId]);

    useEffect(() => {
        if (orderDetails) {
            setPaymentMethod(orderDetails.order.paymentMethod);
            setDeliveryMethod(orderDetails.order.deliveryMethod);
            if (orderDetails.order.pickupPointId !== null && selectedDeliveryMethod && selectedDeliveryMethod.id !== null) {
                fetchSellingSessions(orderDetails.order.pickupPointId);
            }
        }
    }, [orderDetails]);

    const setPaymentMethod = (id: string) => {
        const method = paymentMethods.find(x => x.id === id);
        if (method !== null) {
            setSelectedPaymentMethod(method);
        }
    };

    const setDeliveryMethod = (id: string) => {
        const method = deliveryMethods.find(x => x.id == id);
        if (method) {
            setSelectedDeliveryMethod(method);
        }
    };

    const fetchSellingSessions = (selectedPickupPointId: string) => {
        getSellingSessionsPaginatedService(1, 20, selectedPickupPointId, selectedDeliveryMethod?.id).then((response: any) => {
            setSellingSessions(response.data?.items);
        });
    }

    const handlePayClick = () => {
        if (isPayClicked) return;
        setIsPayClicked(true);
        navigate(`/przelewy24payment/${orderId}`);
    };

    const getOrderData = (orderId: string | null): void => {
        if (orderId) {
            getOrderDetails(orderId).then((response) => {
                if (response?.data) {
                    setOrderDetails(response?.data as OrderDetailsModel)
                    setPaymentMethod(response?.data?.order?.paymentMethod);
                }
            });
        }
    }

    useEffect(() => {
        if (orderDetails?.order?.pickupPointId && orderDetails?.order?.pickupPointId !== emptyGuid) {
            getPickupPoint(orderDetails?.order?.pickupPointId)
        }
    }, [orderDetails])


    const getPaymentMethodsOnOnit = (): void => {
        getPayments().then((response) => {
            if (response) {
                setPaymentMethods(response.data as PaymentMethod[])
            }
        })
    };

    const getDeliveryMethodsOnOnit = (): void => {
        getDeliveries().then((response) => {
            if (response) {
                setDeliveryMethods(response.data as DeliveryMethod[])
            }
        })
    };

    const getPickupPoint = (id: any): void => {
        getPickupPointById(id).then((response) => {
            if (response) {
                const pickupPoint = response.data as PickupPoint
                setPickupPoint(pickupPoint);
            }
        })
    };

    return (
        <div className={styles.container}>
            <div className={styles.decor}>
                <div className={styles.header}>{t("orderConfirmation.header")}</div>
            </div>
            <div className={styles.content}>
                <div className={styles.left_side}>
                    <div className={styles.message}>Prosimy o opłacenie zamówienia nr <span className={styles.bold}>{orderDetails?.order.referenceNumber}</span>.
                        Na adres <span className={styles.bold}>{orderDetails?.userDetails.contact?.email}</span> wyślemy potwierdzenie zamówienia i
                        podsumowanie zakupów. Jeżeli e-mail nie dotrze w ciągu 15 minut, sprawdź również spam
                        oraz folder Oferty.
                    </div>
                    <OrderConfirmationProducts orderDetails={orderDetails}></OrderConfirmationProducts>
                </div>
                <div className={styles.right_side}>
                    <OrderConfirmationSummary selectedPaymentMethod={selectedPaymentMethod} handlePayClick={handlePayClick} orderDetails={orderDetails}></OrderConfirmationSummary>
                </div>
            </div>
        </div>
    )
}

